import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { DataService, DeviceCloneQuery } from "app/services/data.service";
import { UserStore } from "app/@core/stores/user.store";
import { GuiService } from "app/services/gui.service";
import { Location } from "../../../../shared-gen/Model/Locations/Location";

@Component({
    selector: "ngx-device-clone-dialog",
    templateUrl: "./device-clone-dialog.component.html",
    styleUrls: ["./device-clone-dialog.component.scss"]
})
export class DeviceCloneDialogComponent extends Localized {

    _sourceID: string = "";
    get sourceID(): string { return this._sourceID };
    set sourceID(value: string) {
        this._sourceID = value;
        this.targetID = this._sourceID.split("-").slice(1).join("-");
        this.updateIsValidClone();
    }
    _targetID: string = "";
    get targetID(): string { return this._targetID };
    set targetID(value: string) {
        this._targetID = value;
        this.updateIsValidClone();
    }

    overwriteExisting: boolean = false;
    cloneConfig: boolean = false;
    cloneProducts: boolean = false;
    cloneMenu: boolean = false;
    clonePrices: boolean = false;
    cloneSelections: boolean = false;
    skipIfExisting: boolean = true;
    targetPostfix: string = "";
    localClone: boolean = true;
    remoteUrl: string = "https://eude-sandbox-vm-01.iovent.net/api/1.0";
    //sourceLicenseKey: string = "";
    userID: string = "iovent";
    password: string = "";
    currentProviderID: string = "";
    accessibleLocations: Location[] = [];
    selectedLocationId: string = null;
    _isValidClone = false;

    importing = 0;

    constructor(public dialogRef: MatDialogRef<DeviceCloneDialogComponent>,
        translationService: TranslationService, protected dataService: DataService, protected guiService: GuiService, private userStore: UserStore) {
        super(translationService);
        this.currentProviderID = userStore.getIoventUser().ProviderID;
    }

    async ngOnInit(): Promise<void> {
        this.accessibleLocations = await this.dataService.getUserLocations();
    }

    selectLocation(value: string){
        this.selectedLocationId = value;
        this.updateIsValidClone();
    }

    async confirm() {
        this.importing = 0.01;
        const query: DeviceCloneQuery = {
            SourceID: this.sourceID,
            TargetID: `${this.currentProviderID}-${this.targetID}`,
            OverwriteExisting: this.overwriteExisting,
            TargetPostfix: this.targetPostfix,
            TargetLocationID: this.selectedLocationId,
            CloneConfig: this.cloneConfig,
            CloneProducts: this.cloneProducts,
            CloneMenu: this.cloneMenu,
            ClonePrices: this.clonePrices,
            CloneSelections: this.cloneSelections,
            SkipIfExisting: this.skipIfExisting
        }
        try {
            this.localClone ?
                await this.dataService.cloneDevice(query) :
                await this.dataService.importDevice(
                    this.remoteUrl,
                    this.userID,
                    this.password,
                    query
                )
        } catch (error) {
            const apiStatusResponse = error.status;
            const errorMessageVocabId = {
                400: "🌐Messages.CloneDevice.Error.BadRequest",
                403: "🌐Messages.CloneDevice.Error.Forbidden",
                404: "🌐Messages.CloneDevice.Error.NotFound",
                409: "🌐Messages.CloneDevice.Error.Conflict"
            }[apiStatusResponse] || "🌐Messages.ErrorWhileCloning";

            this.guiService.showToast(this.translate(errorMessageVocabId), this.translate("🌐Messages.CloneDevice.Error"), "danger", 15000);
            this.importing = 0;
        }
    }

    private updateIsValidClone(){
        this._isValidClone = this.selectedLocationId != undefined && this.selectedLocationId.trim().length > 0
            && this._sourceID != undefined && this._sourceID.trim().length > 0
            && this._targetID != undefined && this._targetID.trim().length > 0;
    }
}
