<nb-card [nbSpinner]="(importing > 0 && importing < 1) " nbSpinnerSize="giant">

    <nb-card-header>{{"🌐Device.DeviceCloneImport" | translate:lang}}</nb-card-header>

    <nb-card-body>

        <div class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.SourceID" | translate:lang}}</label>
            </div>
            <div class="col-10">
                <input [(ngModel)]="sourceID" nbInput fullWidth />
            </div>
        </div>
        <div class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.TargetID" | translate:lang}}</label>
            </div>
            <div class="col-10">
                <div class="row align-items-center padding-x">
                    <div class="col-auto prefix" >{{ currentProviderID }}-</div>
                    <input class="col" [(ngModel)]="targetID" nbInput fullWidth />
                </div>
            </div>
        </div>
        <div class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.Overwrite" | translate:lang}}</label>
            </div>
            <div class="col-2">
                <nb-checkbox status="info" [(checked)]="overwriteExisting"></nb-checkbox>
            </div>
        </div>

        <div class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.TargetLocation" | translate:lang}}</label>
            </div>
            <div class="col-10">
                <nb-select (selectedChange)="selectLocation($event)" fullWidth>
                    <nb-option *ngFor="let accessibleLocation of accessibleLocations"
                    [value]="accessibleLocation.ID">{{accessibleLocation.Name}} ({{accessibleLocation.ID}})</nb-option>
                </nb-select>
            </div>
        </div>

        <h6>{{"🌐Clone.CloneDependencies" | translate:lang}}</h6>

        <div class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.CloneConfig" | translate:lang}}</label>
            </div>
            <div class="col-2">
                <nb-checkbox status="info" [(checked)]="cloneConfig"></nb-checkbox>
            </div>
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.CloneProducts" | translate:lang}}</label>
            </div>
            <div class="col-2">
                <nb-checkbox status="info" [(checked)]="cloneProducts"></nb-checkbox>
            </div>

        </div>

        <div class="row align-items-center my-3">

            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.CloneMenu" | translate:lang}}</label>
            </div>
            <div class="col-2">
                <nb-checkbox status="info" [(checked)]="cloneMenu"></nb-checkbox>
            </div>
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.ClonePrices" | translate:lang}}</label>
            </div>
            <div class="col-2">
                <nb-checkbox status="info" [(checked)]="clonePrices"></nb-checkbox>
            </div>
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.CloneSelections" | translate:lang}}</label>
            </div>
            <div class="col-2">
                <nb-checkbox status="info" [(checked)]="cloneSelections"></nb-checkbox>
            </div>
        </div>

        <ng-container *ngIf="cloneConfig||cloneProducts||cloneMenu||clonePrices||cloneSelections">
            <h6>{{"🌐Clone.DependenciesHandling" | translate:lang}}</h6>

            <div class="row align-items-center my-3">
                <div class="col-2">
                    <label class="mb-0">{{"🌐Clone.SkipIfExisting" | translate:lang}}</label>
                </div>
                <div class="col-2">
                    <nb-checkbox status="info" [(checked)]="skipIfExisting"></nb-checkbox>
                </div>
                <div class="col-2">
                    <label class="mb-0">{{"🌐Clone.TargetPostfix" | translate:lang}}</label>
                </div>
                <div class="col-6">
                    <input [(ngModel)]="targetPostfix" nbInput fullWidth />
                </div>
            </div>
        </ng-container>

        <h6>{{"🌐Clone.DataSource" | translate:lang}}</h6>

        <div class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.LocalClone" | translate:lang}}</label>
            </div>
            <div class="col-2">
                <nb-checkbox status="info" [(checked)]="localClone"></nb-checkbox>
            </div>
        </div>


        <div *ngIf="!localClone" class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.RemoteUrl" | translate:lang}}</label>
            </div>
            <div class="col-10">
                <input [(ngModel)]="remoteUrl" nbInput fullWidth />
            </div>
        </div>
        <!--
    <div *ngIf="!localClone" class="row align-items-center my-3">
      <div class="col-2">
        <label class="mb-0">{{"🌐Clone.SourceLicenseKey" | translate:lang}}</label>
      </div>
      <div class="col-10">
        <input [(ngModel)]="sourceLicenseKey" nbInput fullWidth/>
      </div>
    </div>
    -->
        <div *ngIf="!localClone" class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.UserID" | translate:lang}}</label>
            </div>
            <div class="col-10">
                <input [(ngModel)]="userID" nbInput fullWidth />
            </div>
        </div>
        <div *ngIf="!localClone" class="row align-items-center my-3">
            <div class="col-2">
                <label class="mb-0">{{"🌐Clone.Password" | translate:lang}}</label>
            </div>
            <div class="col-10">
                <input type="password" [(ngModel)]="password" nbInput fullWidth />
            </div>
        </div>

    </nb-card-body>

    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 mr-2">{{"🌐General.Cancel" |
            translate:lang}}</button>
        <button [disabled]="!_isValidClone" nbButton status="success" (click)="confirm()" class="w-100 ml-2">
            {{"🌐Clone.CloneDevice" | translate:lang}}</button>
        <!--[disabled]="selected.length == 0"-->
    </nb-card-footer>

</nb-card>
