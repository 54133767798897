import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NbAuthService } from "@nebular/auth";
import { UUID } from "angular2-uuid";
import { UsersService } from "app/@core/backend/common/services/users.service";
import { InitUserService } from "app/@theme/services/init-user.service";
import { environment } from "environments/runtime-environment";
import { Observable } from "rxjs";
import { StatisticsType } from "shared-gen/Backend/Statistics/StatisticsType";
import { Transaction } from "shared-gen/Events/Transaction";
import { Role } from "shared-gen/Model/Auth/Role";
import { RestartComponent } from "shared-gen/Model/Devices/Config/RestartComponent";
import { Device } from "shared-gen/Model/Devices/Device";
import { DeviceFillStatus } from "shared-gen/Model/Devices/DeviceFillStatus";
import { DeviceInfo } from "shared-gen/Model/Devices/DeviceInfo";
import { DeviceOverview } from "shared-gen/Model/Devices/DeviceOverview";
import { DevicePairing } from "shared-gen/Model/Devices/DevicePairing";
import { DeviceShort } from "shared-gen/Model/Devices/DeviceShort";
import { ScreenResolution } from "shared-gen/Model/Devices/ScreenResolution";
import { PortalDeviceTableItem } from "shared-gen/Model/Devices/Views/PortalDeviceTableItem";
import { EventComponentGroup } from "shared-gen/Model/Events/EventComponentGroup";
import { LanguageCode } from "shared-gen/Model/Localization/LanguageCode";
import { LanguagePack } from "shared-gen/Model/Localization/LanguagePack";
import { Location } from "shared-gen/Model/Locations/Location";
import { LocationInfo } from "shared-gen/Model/Locations/LocationInfo";
import { LocationShort } from "shared-gen/Model/Locations/LocationShort";
import { PortalLocationTableItem } from "shared-gen/Model/Locations/Views/PortalLocationTableItem";
import { MobileMenu } from "shared-gen/Model/MobileOrders/MobileMenu";
import { Product } from "shared-gen/Model/Products/Product";
import { ProductBase } from "shared-gen/Model/Products/ProductBase";
import { CashBalanceReport } from "shared-gen/Model/Reports/CashBalanceReport";
import { SalesPaymentFilter } from "shared-gen/Model/Reports/SalesPaymentFilter";
import { SalesReport } from "shared-gen/Model/Reports/SalesReport";
import { Grouping } from "shared-gen/Model/Statistics/Grouping";
import { TimeResolution } from "shared-gen/Model/Statistics/TimeResolution";
import { User } from "shared-gen/Model/Users/User";
import { Dependencies } from "shared-gen/Model/Utils/Dependencies";
import { Dependents } from "shared-gen/Model/Utils/Dependents";
import { Entity } from "shared-gen/Model/Utils/Entity";
import { EntityType } from "shared-gen/Model/Utils/EntityType";
import { QRVoucher } from "shared-gen/Model/Vouchers/QR/QRVoucher";
import { IDAndName } from "shared-gen/Utils/Model/IDAndName";
import { ILogEntry } from "shared-gen/Utils/Model/ILogEntry";
import { ChartJSData } from "shared/backend/statistics/ChartJSData";
import { ProductBaseFun } from "shared/model/products/product-base.fun";
import { DependentsFun } from "shared/model/utils/dependents.fun";
import { TranslationService } from "shared/services/translation.service";
import { getEndpointForEntityType } from "../@core/entity-types";
import { APIEndpoint } from "../@models/core";
import { isAllowedImageType } from "../@models/core.fun";
import { StorageService } from "./storage.service";

export interface ListOptions {
    OrderBy?: string
    OrderAsc: boolean
    PageIndex: number
    PageSize: number
    LocationID?: string | string[]
}

export interface ListResponse<T = any> { // TODO: Union of possible types instad of any
    Items: T[]
    PageIndex: number
    PageSize: number
    TotalCount: number
    TotalPageCount: number
    IsIncomplete: boolean
}


/**
 * switches usage of global filters
 */
type Filtered = "unfiltered" | "filteredlocations" | "filtereddevices" | "filtered";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    get apiUrl(): string {
        return environment().backendUrl;
    }

    cachedLocations: Record<string, Location> = {};
    cachedDevices: Record<string, Device> = {};

    cachedAllLocations: Record<string, IDAndName> = {};
    cachedAllDevices: Record<string, IDAndName> = {};

    constructor(protected http: HttpClient,
        protected storageService: StorageService,
        private translationService: TranslationService,
        private authService: NbAuthService,
        private usersService: UsersService,
        protected initUserService: InitUserService,
    ) {

    }

    async getFromEndpoint<T>(endpoint: string): Promise<T> {
        try {
        return await this.http.get<T>(`${this.apiUrl}/data/${endpoint}`).toPromise();
        } catch (e) {
        console.error(e);
        }
    }

    /**
     * reset after logout
     */
    logout() {
        this.cachedLocations = null;
        this.cachedDevices = null;
    }

    /**
     * Gets the current version number of the Backend software.
     */
    async getBackendVersion(): Promise<string> {
        return await this.http.get(`${this.apiUrl}/system/info/version`, { responseType: "text" }).toPromise();
    }

    /**
     * Gets the newest version that has a changelog.
     */
    async getChangeLogVersionString(): Promise<string> {
        return await this.http.get(`${this.apiUrl}/system/changelog/version`, { responseType: "text" }).toPromise();
    }

    /**
     * Gets the latest changelog for the given language.
    */
    async getChangeLog(language: LanguageCode): Promise<string> {
        return await this.http.get(`${this.apiUrl}/system/changelog?language=${language}`, { responseType: "text" }).toPromise();
    }

    /**
     * Gets the current language pack.
     */
    async getLanguagePack(key: string | null): Promise<LanguagePack> {
        // Read from Backend API
        if (!key)
            return await this.http.get<LanguagePack>(`${this.apiUrl}/system/languagepack`).toPromise();
        return await this.http.get<LanguagePack>(`${this.apiUrl}/system/languagepack/${key}`).toPromise();
    }

    async getAll<T>(endpoint: APIEndpoint): Promise<{Items:T[]}> {
        try {
            return await this.http.get<{Items:T[]}>(`${this.apiUrl}/data/${endpoint}`).toPromise();
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Retrieves the unfiltered list of Locations the current user might access.
     */
    async getUserLocations(): Promise<Location[]> {
        // Ugly cast, because getList results in a ListResponse, even tough its return type is an array type.
        // Created issue to refactor this. (#1763)
        const result = (await this.getList<Location>(`/data/locations`,<ListOptions>{},{}, "unfiltered")) as any as ListResponse;
        return result.Items;
    }

    async get<T>(id: string, endpoint: APIEndpoint, options: object = null): Promise<T> {
        try {
            let params = ''
            params = this.addOptionsToParams(options, params);
            return await this.http.get<T>(`${this.apiUrl}/data/${endpoint}/${id}?${params}`).toPromise();
        } catch (e) {
            console.error(e);
        }
    }

    async post<T>(endpoint: APIEndpoint, options: object = null, body: any = ""): Promise<T> {
        try {
            let params = ''
            params = this.addOptionsToParams(options, params);
            let data = await this.http.post<T>(`${this.apiUrl}/data/${endpoint}?${params}`, body, { headers: { 'Content-Type': 'application/json' } }).toPromise();
            return data;
        } catch (e) {
            console.error(e);
            throw new Error(e.error);
        }
    }

    async postbase<T>(url: string, options: object = null, body: any = ""): Promise<T> {
        try {
            let params = ''
            params = this.addOptionsToParams(options, params);
            let data = await this.http.post<T>(`${this.apiUrl}/${url}?${params}`, body).toPromise();
            return data;
        } catch (e) {
            console.error(e);
            throw new Error(e.error);
        }
    }

    async postWithPlainResponse<T>(url: string, options: object = null, body: any = ""): Promise<T> {
        let params = '';
        params = this.addOptionsToParams(options, params);
        return await this.http.post<T>(`${this.apiUrl}/${url}?${params}`, body).toPromise();
    }

    /**
     * With this function a device can be imported from a remote connection to the current backend.
     * @param url The remote url, where the device should be retrieved from.
     * @param userID The user id for basic auth
     * @param password The password for basic auth
     * @param query The query parameters
     */
    async importDevice(url: string, userID: string, password: string, query: DeviceCloneQuery) {
        return this.postWithPlainResponse("x/devices/import", { Url: url, UserID: userID, Password: password, ...query }, null);
    }

    /**
     * With this function a device can be cloned within the current backend.
     * @param query The query parameters
     */
    async cloneDevice(query: DeviceCloneQuery) {
        return this.postWithPlainResponse("x/devices/clone", { ...query }, null);
    }

    async postbaseX<T>(url: string, options: object = null, body: any = ""): Promise<T> {
        try {
            let params = ''
            params = this.addOptionsToParams(options, params);
            let data = await this.http.post<T>(`${this.apiUrl}/${url}?${params}`, body, { headers: { 'Content-Type': 'application/json' } }).toPromise();
            return data;
        } catch (e) {
            console.error(e);
            throw new Error(e.error);
        }
    }

    async save(data: Entity, entityType: EntityType, propagate: boolean) {
        const endpoint = getEndpointForEntityType(entityType);
        await this.http.post(`${this.apiUrl}/data/${endpoint}?propagate=${propagate}`, data).toPromise();
    }

    async delete(id: string, entityType: EntityType): Promise<any> {
        const dependents = await this.getDependents([id], entityType);
        if (false == DependentsFun.hasAny(dependents, id)) {
            const endpoint = getEndpointForEntityType(entityType);
            await this.http.delete(`${this.apiUrl}/data/${endpoint}/${id}`).toPromise();
        } else {
            throw new Error(this.translationService.translate("🌐Messages.EntityInUse"));
        }
    }

    /**
     * Saves the given user. If the password should also be changed,
     * use the newPassword parameter, otherwise null.
     */
    async saveUser(user: User, isSelfEditing: boolean, newPassword: string | null = null) {
        if (false == isSelfEditing) {
            // Edit a different user
            const endpoint = getEndpointForEntityType(EntityType.User);
            await this.http.post(`${this.apiUrl}/data/${endpoint}`, user).toPromise();
            if (newPassword != null)
                await this.http.post(`${this.apiUrl}/data/users/password/${user.ID}`,
                    JSON.stringify(newPassword), { headers: { 'Content-Type': 'application/json' } }).toPromise();
        }
        else {
            // Self-edit current user
            await this.http.post(`${this.apiUrl}/data/users/profile`, user).toPromise(); // all data is submitted, but only some fields are used server-side
            if (newPassword != null)
                await this.http.post(`${this.apiUrl}/data/users/profile/password`,
                    JSON.stringify(newPassword), { headers: { 'Content-Type': 'application/json' } }).toPromise();
        }
    }


    async saveUserColumns(tableName: string, column: string, op: "hide" | "show" | "left" | "right", defaultColumns: string[]): Promise<string[]> {
        let result = await this.postbase<string[]>(`data/users/profile/columns`, { TableName: tableName, Column: column, Op: op }, defaultColumns);
        await this.initUserService.initCurrentUser().toPromise();//.subscribe();
        return result;
    }


    addOptionsToParams(options: object, params: string) {
        if (options) {

            if (params !== '') {
                params += "&";
            }

            let arrayOpts = [];
            for (let opt in options) {
                if (Array.isArray(options[opt])) {
                    for (let o of options[opt]) {
                        arrayOpts.push(`${opt}=${encodeURIComponent(o)}`);
                    }
                    delete options[opt];
                }
                if (options[opt] === undefined || options[opt] === null) {
                    delete options[opt];
                }
            }
            params += arrayOpts.join('&');

            if (params !== '') {
                params += "&";
            }

            params += Object.entries(options).map(([key, val]) => `${key}=${val}`).join('&');
        }
        return params;
    }


    addFilterOptions(filtered: Filtered, additionalOptions: object, dateMode: "ZoneDate" | "Date" = "ZoneDate") {


        if (filtered === "filteredlocations" || filtered === "filtereddevices" || filtered === "filtered") {

            if (additionalOptions['LocationID'] === undefined)
                additionalOptions['LocationID'] = this.storageService.getLocationFilterIDs();

            if (additionalOptions['DeviceID'] === undefined)
                additionalOptions['DeviceID'] = this.storageService.getDeviceFilterIDs();
        }

        if (filtered === "filtereddevices" || filtered === "filtered") {

            if (additionalOptions['LocationID'] === undefined)
                additionalOptions['LocationID'] = this.storageService.getLocationFilterIDs();

            if (additionalOptions['DeviceID'] === undefined)
                additionalOptions['DeviceID'] = this.storageService.getDeviceFilterIDs();
        }

        if (filtered === "filtered") {

            let dateRange = this.storageService.getDateRange();
            if (dateRange) {

                const formatDate = (date: Date) => {
                    //  return date.toISOString();
                    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "T";
                };

                // Add "FromZoneDate" or "FromDate"
                if (additionalOptions["From" + dateMode] === undefined) {
                    additionalOptions["From" + dateMode] = formatDate(dateRange.start) + "00:00:00.000";
                }

                // Add "ToZoneDate" or "ToDate"
                if (additionalOptions["To" + dateMode] === undefined) {
                    additionalOptions["To" + dateMode] = formatDate(dateRange.end) + "23:59:59.999";
                }
            }

        }

    }

    /**
     * Gets the dependent entities of the given entity, identified by ID and type.
     */
    async getDependents(ids: string[], type: EntityType): Promise<Dependents> {
        return await this.http.get<Dependents>(`${this.apiUrl}/data/dependents?Type=${type}&${ids.map(id => `ID=${id}`).join('&')}`).toPromise();
    }

    /**
     * From the entity with the given ID and type, gets the IDs and names of the used dependencies (child entities),
     * which have a different hash in the database than the ones stored in the price list. Sorted alphabetically by ID.
     */
    async getChangedDependencies(id: string, entityType: EntityType): Promise<Dependencies> {
        const endpoint = getEndpointForEntityType(entityType);
        return await this.http.get<Dependencies>(`${this.apiUrl}/data/${endpoint}/changed-dependencies/${id}`).toPromise();
    }

    /**
     * In the entity with the given ID and type, updates all dependencies (child entities)
     * with the given IDs by replacing them with the version from the database.
     * The names of the given dependencies are ignored and can be omitted.
     * The updated and saved entity is returned.
     */
    async updateDependencies<T extends Entity>(id: string, entityType: EntityType, dependencies: Dependencies): Promise<T> {
        const endpoint = getEndpointForEntityType(entityType);
        return await this.http.post<T>(`${this.apiUrl}/data/${endpoint}/update-dependencies/${id}`,
            dependencies).toPromise();
    }

    async checkID(id: string, oldID: string, endpoint: APIEndpoint): Promise<boolean> {
        if (oldID === undefined) oldID = null;
        if (!id) return false;
        if (oldID && id === oldID) return true;
        try {
            let nID = (await this.http.get(`${this.apiUrl}/data/${endpoint}/new-id/${id}`, { responseType: 'text' }).toPromise()) as string;
            return nID === id;
        } catch (e) {

            return false;
        }
    }

    /**
     * Gets a new ID (recommended by the Backend) based on the given ID of the given entity type.
     */
    async getNewID(id: string, entityType: EntityType): Promise<string> {
        const endpoint = getEndpointForEntityType(entityType);
        return await this.http.get<string>(`${this.apiUrl}/data/${endpoint}/new-id/${id}`,
            { responseType: 'text' as any }).toPromise();
    }

    /**
     * Returns true, iff the current user is allowed to (over)write or delete
     * the entity of this type with the given ID. This could be false for example,
     * when the entity is a global entity but the user has only restricted location
     * access, or when the locations don't match.
     * TODO: Currently not all endpoints support this method. If there is a problem, we
     * return boolean for backwards compatibility.
     */
    async mayWriteEntity(id: string, entityType: EntityType): Promise<boolean> {
        const endpoint = getEndpointForEntityType(entityType);
        try {
            return await this.http.get<boolean>(`${this.apiUrl}/data/${endpoint}/may-write/${id}`).toPromise();
        }
        catch {
            return true;
        }
    }

    /**
     * Returns true, iff the given ID of the given entity type already exists on the Backend.
     */
    async existsEntity(id: string, entityType: EntityType): Promise<boolean> {
        try {
            return await this.getNewID(id, entityType) != id;
        } catch (e) {
            console.log(e);
            return false;
        }
    }


    /**
     * Loads filtered|sorted data from the API
     * @param url API-Endpoint
     * @param listOptions ListOptions
     * @param additionalOptions Additional options like specific filters
     * options and additionalOptions will be encoded as URL param string i.e ?a=b&c=1,2
     * @param resolve { [key: string]: string } calls "value"-getter function to resolve "key" as "value"-Object.
     * i.e: {"DeviceID": "Device"} calls getDeviceById( with the value of the field DeviceID)
     */
    async getList<T>(url: string, listOptions: ListOptions, additionalOptions: object, filtered: Filtered, resolve: { [key: string]: string } = null): Promise<T[]> {

        if (!listOptions) {
            listOptions = <ListOptions>{};
        }
        if (!listOptions.OrderBy) { // TODO: MK check what this does
            delete listOptions.OrderBy;
        }
        if (listOptions.OrderAsc === undefined) {
            listOptions.OrderAsc = true;
        }

        listOptions.PageIndex = -1;
        listOptions.PageSize = -1;

        this.addFilterOptions(filtered, additionalOptions);

        let params = '';
        params = this.addOptionsToParams(listOptions, params);
        params = this.addOptionsToParams(additionalOptions, params);

        let data = (await this.http.get(`${this.apiUrl}${url}?${params}`).toPromise() as any);

        if (resolve != null) {
            for (let key in resolve) {
                for (let _itemKey in data) {
                    let res = await this[`get${resolve[key]}ById`](data[_itemKey][key]);
                    data[_itemKey][resolve[key]] = res;
                }
            }
        }

        return data;
    }



    /**
     * Loads paginated|filtered|sorted data from the API
     * @param url API-Endpoint
     * @param listOptions ListOptions
     * @param additionalOptions Additional options like specific filters
     * options and additionalOptions will be encoded as URL param string i.e ?a=b&c=1,2
     * @param resolve { [key: string]: string } calls "value"-getter function to resolve "key" as "value"-Object.
     * i.e: {"DeviceID": "Device"} calls getDeviceById( with the value of the field DeviceID)
     */
    async getPagedList<T>(url: string, listOptions: ListOptions, additionalOptions: object, filtered: Filtered, resolve: { [key: string]: string } = null, dateMode: "ZoneDate" | "Date" = "ZoneDate"): Promise<ListResponse<T>> {

        if (!listOptions) {
            listOptions = <ListOptions>{};
        }
        if (!listOptions.OrderBy) { // TODO: MK check what this does
            delete listOptions.OrderBy;
        }
        if (listOptions.OrderAsc === undefined) {
            listOptions.OrderAsc = true;
        }
        if (!listOptions.PageIndex) {
            listOptions.PageIndex = 0;
        }
        if (!listOptions.PageSize) {
            listOptions.PageSize = 10;
        }

        this.addFilterOptions(filtered, additionalOptions, dateMode);

        let params = '';
        params = this.addOptionsToParams(listOptions, params);
        params = this.addOptionsToParams(additionalOptions, params);

        let data = (await this.http.get(`${this.apiUrl}${url}?${params}`).toPromise() as any);
        if (!data.Items) {
            data = {
                TotalCount: data.length,
                Items: data,
                PageSize: data.length,
                PageIndex: 0
            } as ListResponse<T>;
        }

        if (resolve != null) {
            for (let key in resolve) {
                for (let _itemKey in data.Items) {
                    let res = await this[`get${resolve[key]}ById`](data.Items[_itemKey][key]);
                    data.Items[_itemKey][resolve[key]] = res;
                }
            }
        }

        return data;
    }

    /**
     * Get the data list and resolves the Device details
     * @param dataType
     * @param listOptions ListOptions
     * @param additionalOptions Additional options like specific filters
     */
    async getPagedListOfDataType<T>(dataType: APIEndpoint, listOptions: ListOptions, additionalOptions: object, filtered: Filtered, dateMode: "ZoneDate" | "Date" = "ZoneDate"): Promise<ListResponse<T>> {
        //const resolve: { [key: string]: string } = {'DeviceID': 'Device'}; // TODO: check if needed // a lot of overhead if not cached
        const resolve: { [key: string]: string } = {};
        try {
            return await this.getPagedList<T>(`/data/${dataType}`, listOptions, additionalOptions, filtered, resolve, dateMode);
        } catch (e) {
            console.error(e);
        }
    }

    async getPagedListOfUsers(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<ListResponse<User>> {
        let users: ListResponse<User>;
        // TODO: caching, cache validation
        users = await this.getPagedList<User>('/data/users/accounts', listOptions, additionalOptions, filtered);
        return users;
    }

    async userExists(userID: string): Promise<boolean> {
        return await this.http.get<boolean>(`${this.apiUrl}/data/users/exists/` + userID).toPromise();
    }

    async deleteUser(id: string): Promise<any> {
        await this.http.delete(`${this.apiUrl}/data/users/accounts/${id}`).toPromise();
    }

    async getRoles(includingThisUser = false): Promise<Role[]> {
        // TODO: caching, cache validation
        const roles = await this.http.get<Role[]>(`${this.apiUrl}/data/users/roles?includingThisUser=${includingThisUser}`).toPromise();
        return roles;
    }

    async getLocationNames(options: object = null): Promise<IDAndName[]> {
        // TODO: caching, cache validation
        let params = '';
        params = this.addOptionsToParams(options, params);
        let data = (await this.http.get(`${this.apiUrl}/data/locations/name/*?${params}`).toPromise() as IDAndName[]);
        //this.cachedAllDevices = data;
        return data;
    }

    async getLocationNameById(id: string): Promise<IDAndName> {
        // TODO: caching, cache validation
        return await this.getLocationNames({ LocationIDs: [id] })[0];
    }

    async getLocations(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<Location[]> {
        // TODO: caching, cache validation
        let locations = await this.getList<Location>('/data/locations/*', listOptions, additionalOptions, filtered);
        return locations;
    }

    async getLocationById(id: string, withDevices = false): Promise<Location> {
        // TODO: caching, cache validation
        try {
            return await this.http.get<Location>(`${this.apiUrl}/data/locations/${id}`).toPromise();
        } catch (e) {
            console.error(e);
        }
    }

    async getLocationInfos(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<LocationInfo[]> {
        // TODO: caching, cache validation
        let locations = await this.getList<LocationInfo>('/data/locations/info/*', listOptions, additionalOptions, filtered);
        return locations;
    }

    async getLocationShorts(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<LocationShort[]> {
        // TODO: caching, cache validation
        let locations = await this.getList<LocationShort>('/data/locations/short/*', listOptions, additionalOptions, filtered);
        return locations;
    }

    /*
   async getLocationOverviewById(id: string, withDevices = false): Promise<LocationOverview> {
     // TODO: caching, cache validation
     try {
       return await this.http.get<LocationOverview>(`${this.apiUrl}/data/locations/${id}`
         + (withDevices ? "?withDevices=true": "")).toPromise();
     } catch (e) {
       console.error(e);
     }
   }
   */

    async getPagedListOfPortalLocationTableItems(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<ListResponse<PortalLocationTableItem>> {
        let locations: ListResponse<PortalLocationTableItem>;
        locations = await this.getPagedList<PortalLocationTableItem>('/data/locations/views/portal-table', listOptions, additionalOptions, filtered);
        return locations;
    }


    async getDeviceNames(options: object = null): Promise<IDAndName[]> {
        // TODO: caching, cache validation
        let params = this.addOptionsToParams(options, '');
        let data = (await this.http.get(`${this.apiUrl}/data/devices/name/*?${params}`).toPromise() as IDAndName[]);
        //this.cachedAllDevices = data;
        return data;
    }

    async getDeviceNameById(id: string): Promise<IDAndName> {
        // TODO: caching, cache validation
        return await this.getDeviceNames({ DeviceID: [id] })[0];
    }

    async getDevices(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<Device[]> {
        // TODO: caching, cache validation
        let devices = await this.getList<Device>('/data/devices/*', listOptions, additionalOptions, filtered);
        await this.embedMissingLocations(devices);
        return devices;
    }

    private async embedMissingLocations(devices: Device[]) { // TODO: obsolete ?
        for (let i in devices) {
            if (!devices[i].LocationInfo) {
                // TODO: dedicated endpoint for short LocationInfo
                devices[i].LocationInfo = await this.getLocationById(devices[i].LocationID);
            }
            // delete devices.Items[i].LocationID; // keep this to not break the base Entity
        }
    }
    async getDeviceReportById(id: string): Promise<DeviceOverview> {
        return (await this.get<DeviceOverview>(id, 'devicereports'));
    }

    async getDeviceSshPort(id: string): Promise<number | undefined> {
        try {
        return (await this.getFromEndpoint<number>(`devices/${id}/ssh-port`));
        } catch (error) {
        return undefined;
        }
    }

    /**
     * Gets a paginated list of all Products
     */
    async getPaginatedAllProducts(): Promise<ListResponse> {
        let options = { PageIndex: 0, PageSize: 1000 } as ListOptions
        return await this.getPagedList('/data/products', options, null, "unfiltered")
    }

    /**
     * Get all product instances by location id. If none is specified the query will search all available instances.
     */
    async getProductInstancesByLocationId(id: string): Promise<Product[]> {
        if (id) {
            return await this.http.get<Product[]>(`${this.apiUrl}/data/locations/products/?LocationID=${id}`).toPromise()
        } else {
            return await this.http.get<Product[]>(`${this.apiUrl}/data/locations/products/`).toPromise();
        }
    }

    async getDeviceById(id: string): Promise<Device> {
        // TODO: caching, cache validation
        return (await this.get<Device>(id, 'devices'));
    }

    async getDeviceInfos(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<DeviceInfo[]> {
        // TODO: caching, cache validation
        let devices = await this.getList<DeviceInfo>('/data/devices/info/*', listOptions, additionalOptions, filtered);
        return devices;
    }

    async getDeviceShorts(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<DeviceShort[]> {
        // TODO: caching, cache validation
        let devices = await this.getList<DeviceShort>('/data/devices/short/*', listOptions, additionalOptions, filtered);
        return devices;
    }
    /*
      async getDeviceReports(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<ListResponse<DeviceOverview>> {
          let devices: ListResponse<DeviceOverview>;
          devices = await this.getList<DeviceOverview>('/data/devicereports', listOptions, additionalOptions, filtered);
          //await this.embedMissingLocations(devices);
          return devices;
      }
    */

  /**
   * retrieves serialized data with base64 encoding
   */
  async getQRVoucherDataInBase64(Id: string): Promise<string> {
    const endpoint = 'qrvouchers/base64';
    return await this.http.get(`${this.apiUrl}/data/${endpoint}/${Id}`, { responseType: 'text' }).toPromise();
  }

  /**
   * retrieves serialized data with base64 encoding
   */
    async getQRCommandOrderProduct(productInstanceId: string): Promise<ArrayBuffer> {
      const endpoint = `qrcommand/order/${productInstanceId}/image`;
      return await this.http.get(`${this.apiUrl}/data/${endpoint}`, { responseType: 'arraybuffer' }).toPromise();
    }

  /***********************************MISC**************************************/

  /**
   * Returns a specific Cash-Balance for a Device
   * @param device
   * @param serial
   */
  async getCashBalance(device: string, serial: number) {
    return await this.http.get<CashBalanceReport>(`${this.apiUrl}/data/cashbalance?DeviceID=${device}&Serial=${serial}`).toPromise();
  }


  /**
   * Get the mobile menu with the given ID, if there is one,
   * otherwise null.
   */
  async getMobileMenu(id: string): Promise<MobileMenu | null> {
    return await this.http.get<MobileMenu | null>(`${this.apiUrl}/mobileorder/menu/${id}`).toPromise();
  }

  /**
   * Get the mobile menus.
   */
  async getPagedListOfMobileMenus(options: any): Promise<ListResponse<MobileMenu>> {
    return await this.getPagedList<MobileMenu>(`/mobileorder/menus`, options, {}, null);
  }

  private async uploadFile(file: File): Promise<string> {
    if (isAllowedImageType(file.type)) {
      const reader = new FileReader();

      let fcontent = await (new Promise((resolve) => {
        reader.onload = e => resolve(reader.result);
        reader.readAsDataURL(file);
      }));

      const postData = new FormData();
      postData.append('file', file, file.name);

      let resp;
      try {
        return (await this.http.post(`${this.apiUrl}/data/images/file`, postData, { responseType: 'text' }).toPromise()) as string;
      } catch (e) {
        console.log(resp);
        console.log(e);
      }
      console.log('test');
    } else {
      throw new Error('Type not allowed!');
    }
  }
    async getPagedListOfPortalDeviceTableItems(listOptions: ListOptions, additionalOptions: object, filtered: Filtered): Promise<ListResponse<PortalDeviceTableItem>> {
        let devices: ListResponse<PortalDeviceTableItem>;
        devices = await this.getPagedList<PortalDeviceTableItem>('/data/devices/views/portal-table', listOptions, additionalOptions, filtered);
        return devices;
    }

    /**
     * Gets all transactions belonging to the given order.
     */
    async getAllTransactionsForOrderID(deviceID: string, orderID: string): Promise<Transaction[]> {
        return (await this.http.get<Transaction[]>(`${this.apiUrl}/data/events/transactions-by-orderid?deviceID=${deviceID}&orderID=${orderID}`).toPromise());
    }


    /**
     * Create the given voucher(s) and return the array of created vouchers.
     */
    async createVoucher(voucherData: QRVoucher, count: number): Promise<QRVoucher[]> {
        const endpoint = getEndpointForEntityType("QRVoucher");
        count <= 0 ? 1 : count;
        return await this.http.post<QRVoucher[]>(`${this.apiUrl}/data/${endpoint}/create?serialized=false&count=${count}`, voucherData).toPromise()
    }

    /**
     * Revoke the given voucher
     */
    async revokeVoucher(voucher: QRVoucher): Promise<QRVoucher[]> {
        const endpoint = getEndpointForEntityType("QRVoucher");
        return await this.http.post<QRVoucher[]>(`${this.apiUrl}/data/${endpoint}/revoke/${voucher.ID}`, {}).toPromise()
    }


    /**
     * Retrieve the given voucher image and return the bitstream as a Promise
     */
    async getVoucherImage(id: string): Promise<any> {
        return await this.http.get(`${this.apiUrl}/data/qrvouchers/image?id=${id}`,
            {
                responseType: 'blob', headers: new HttpHeaders()
                    .set('responseType', 'image/png')
                    .set('authorization', `basic ${this.authService.getToken()}`)
            }
        ).toPromise()
    }


    /***********************************MISC**************************************/


    async getSalesReport(devices: string[], from: Date, to: Date, paymentFilter: SalesPaymentFilter) {
        const params = new URLSearchParams({
            From: from.toISOString(),
            To: to.toISOString(),
            Payment: paymentFilter,
        });
        for (const device of devices) {
            params.append("Devices", device);
        }
        return await this.http.get<SalesReport>(`${this.apiUrl}/data/salesreport?${params}`).toPromise();
    }


    async saveImageToPool(image: File, pool: string, providerID: string) {
        const imageID = await this.uploadFile(image);
        await this.http.post(`${this.apiUrl}/data/images`, {
            ID: imageID,
            ProviderId: providerID,
            Pool: pool
        }).toPromise();
        return imageID;
    }

    /**
     * Checks the given password for validity (enough characters, used character categories, ...).
     * Returns an empty string if the password is ok, or returns an error message in the given
     * language describring the error.
     */
    async validatePassword(password: string, language: LanguageCode): Promise<string> {
        return await this.http.post<string>(`${this.apiUrl}/auth/validate`, JSON.stringify(password), // quotes and escape
            { headers: { 'Content-Type': 'application/json' }, responseType: 'text' as 'json' }).toPromise<string>();
    }

    /**
     * Gets the last known [DeviceFillStatus] for the device with the given ID,
     * if known, otherwise null.
     * Provide a language for the translation of the product names.
     */
    async getDeviceFillStatus(deviceID: string, language: LanguageCode): Promise<DeviceFillStatus> {
        try {
            return await this.http.get<DeviceFillStatus>(
                `${this.apiUrl}/data/devicefillstatus/${deviceID}?language=${language}`).toPromise();
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    /**
     * Starts the Device pairing on the Backend.
     */
    async startPairing(deviceID: string, pairingCode: string): Promise<DevicePairing> {
        return await this.http.post<DevicePairing>(
            `${this.apiUrl}/data/devicepairings/start?deviceID=${deviceID}&pairingCode=${pairingCode}`,
            '').toPromise();
    }


    /***********************************DOWNLOADS**************************************/

    /**
     * Requests a download key for the current user.
     */
    async getDownloadKey(): Promise<string> {
        return await this.http.post<string>(
            `${this.apiUrl}/auth/key`, '', { responseType: 'text' as 'json' }).toPromise();
    }

    getPdfCashBalanceDownloadUrl(device: string, serial: number, language: LanguageCode): string {
        return `${this.apiUrl}/export/pdf/cashbalance?DeviceID=${device}&Serial=${serial}&Language=${language}`;
    }

    getPdfSalesReportDownloadUrl(devices: string[], from: Date, to: Date, paymentFilter: SalesPaymentFilter, language: LanguageCode): string {
        const params = new URLSearchParams({
            From: from.toISOString(),
            To: to.toISOString(),
            Payment: paymentFilter,
            Language: language,
        });
        for (const device of devices) {
            params.append("Devices", device);
        }
        return `${this.apiUrl}/export/pdf/salesreport?${params}`;
    }

    getExcelDownloadUrl(options: any, dataType: string = 'transactions', filtered: Filtered): string {
        let url = `${this.apiUrl}/export/excel/${dataType}`;
        this.addFilterOptions(filtered, options);
        let params = this.addOptionsToParams(options, '');
        return url + '?' + params;
    }

    getDcsJsonUrl(deviceID: string, serial: number, key: string = undefined) {
        const params = new URLSearchParams({
            DeviceID: deviceID,
            Serial: serial.toString()
        });
        if (key) {
            params.set('Key', key);
        }
        return `${this.apiUrl}/export/dcsjson?${params.toString()}`;
    }

    startExcelDownload(options: any, dataType: string = 'transactions', filtered: Filtered): UUID {
        const d = new Date();
        let uuid = UUID.UUID();
        let url = `${this.apiUrl}/export/excel/${dataType}/start?ID=${uuid}`;
        this.addFilterOptions(filtered, options);
        let params = this.addOptionsToParams(options, '');
        url = url + '&' + params;
        this.http.post(url, {}).toPromise();
        return uuid;
    }

    getLicenseFileUrl(options: any, filtered: Filtered, language: LanguageCode): string {
        let url = `${this.apiUrl}/export/license-file`;
        this.addFilterOptions(filtered, options);
        let params = this.addOptionsToParams(options, 'Language=' + language);
        return url + '?' + params;
    }

    async getExcelDownloadStatus(uuid: UUID, callback: (progress) => void, finished: () => void, dataType: string = 'transactions') {
        console.info('Checking file ', uuid);
        setTimeout(async () => {
            const progress = (await this.checkDownload(uuid, dataType)) as any;
            if (progress.Finished) {
                finished();
            } else {
                callback(progress);
                this.getExcelDownloadStatus(uuid, callback, finished, dataType);
            }
        }, 1000);
    }

    async checkDownload(uuid: UUID, dataType: string = 'transactions') {
        try {
            return (await this.http.get(`${this.apiUrl}/export/excel/${dataType}/progress?ID=${uuid}`).toPromise());
        } catch (e) {
            return {
                Finished: false
            };
        }
    }

    async downloadIsFinished(uuid: UUID, callback: (progress) => void, dataType: string = 'transactions') {
        return await (new Promise<void>((resolve, reject) => {
            this.getExcelDownloadStatus(uuid, callback, () => {
                resolve();
            }, dataType);
        }));
    }

    /*********************************STATISTICS***********************************/

    async getStatistics(types: (StatisticsType | string)[], grouping: Grouping, timeResolution: TimeResolution,
        filter: any, query: string, language: LanguageCode, filtered: Filtered): Promise<{ [statisticsType: string]: ChartJSData }> {
        filter['Grouping'] = grouping;
        filter['TimeResolution'] = timeResolution;
        this.addFilterOptions(filtered, filter)
        let params = types.map(it => `Types=${it}&`).join('');
        params = this.addOptionsToParams(filter, params);
        params += "&query=" + query;
        params += "&language=" + language;
        console.log(params);
        return (await this.http.get<{ [statisticsType: string]: ChartJSData }>(`${this.apiUrl}/statistics?${params}`).toPromise());
    }

    async getStatisticsDownloadUrl(hourlySalesTheme: string, grouping: string, filtered: Filtered): Promise<string> {
        const key = await this.getDownloadKey();
        let options = {};
        this.addFilterOptions(filtered, options);
        let params = this.addOptionsToParams(options, '');
        let url = this.apiUrl + `/export/excel/hourlysales?Key=${key}&Theme=${hourlySalesTheme}&Grouping=${grouping}&${params}`;
        return url;
    }

    /***********************************THEME**************************************/

    async getThemes() {
        return Observable.of([
            'TuR'
        ]).toPromise();
    }

    /**
     * Returns the descriptions of the given failure codes in the given language.
     */
    async getFailureCodeDescriptions(failureCodes: string[], languageCode: LanguageCode)
        : Promise<{ [failureCode: string]: string }> {
        try {
            const codesRaw = failureCodes.join("&code=")
            return await this.http.get<{ [failureCode: string]: string }>(
                `${this.apiUrl}/utils/failurecode/?code=${codesRaw}&lang=${languageCode}`).toPromise();
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async getScreenResolution(deviceID: string): Promise<ScreenResolution> {
        let url = `${this.apiUrl}/monitoring/screen-resolution/${deviceID}`;

        const screenResolution = await this.http.get<ScreenResolution>(url).toPromise();
        return screenResolution;
    }

    async getScreenshot(deviceID: string, width: number = null): Promise<Blob> {
        let url = `${this.apiUrl}/monitoring/screenshot/${deviceID}`;
        if (width != null)
            url += `?resizeWidth=${width}`;
        return this.http.get(url, { responseType: 'blob' }).toPromise();
    }

    async clickOnScreen(deviceID: string, x: number, y: number): Promise<void> {
        let url = `${this.apiUrl}/monitoring/click/${deviceID}?x=${x}&y=${y}`;
        return this.http.post<void>(url, "").toPromise();
    }

    async getLog(deviceID: string, entries: number = null): Promise<ILogEntry[]> {
        let url = `${this.apiUrl}/monitoring/log/${deviceID}`;
        if (entries != null)
            url += `?entries=${entries}`;
        let result = this.http.get<ILogEntry[]>(url).toPromise();
        return result;
    }

    async restartDevice(deviceID: string, component: RestartComponent): Promise<void> {
        let url = `${this.apiUrl}/monitoring/restart/${deviceID}?component=${component}`;
        return this.http.post<void>(url, "").toPromise();
    }

    async applyDataUpdate(deviceID: string): Promise<void> {
        let url = `${this.apiUrl}/data/devices/${deviceID}/dataupdate`;
        return this.http.post<void>(url, "").toPromise();
    }

    async openMaintenanceScreen(deviceID: string): Promise<void> {
        let url = `${this.apiUrl}/uicommand/openMaintenanceScreen/${deviceID}`;
        return this.http.post<void>(url, "").toPromise();
    }

    async getEventComponentGroups() {
        return await this.http.get<EventComponentGroup[]>(
            `${this.apiUrl}/system/event-component-groups`).toPromise();
    }

    // TODO: create Product endpoint to directly get Product data from backend
    async getResourceProducts(providerID: string, productType: string[]): Promise<Product[]> {
        const productBases = (await this.getPagedListOfDataType<ProductBase>(getEndpointForEntityType(EntityType.ProductBase),
            <ListOptions>{
                PageIndex: 0,
                PageSize: 1000 // TODO: use un-paginated endpoint ?
            }, {
            ProviderID: providerID,
            ProductType: productType,
        }, "unfiltered")).Items as ProductBase[];

        let products: Product[] = [];
        productBases.forEach(pb => {
            pb.Instances.forEach(i => {
                products.push(ProductBaseFun.toProduct(i, pb));
            });
        });
        return products;
    }

    getPriceListQRCode(priceListID: string): Promise<string> {
        return this.http.post(`${this.apiUrl}/api/qrcommand/data`, {
            Method: "Post",
            Path: `device/pricelist/${priceListID}`
        }, { responseType: "text" }).toPromise();
    }

    getInitiatorQRCode(initiator: string): Promise<string> {
        return this.http.post(`${this.apiUrl}/api/qrcommand/data`, {
            Method: "Post",
            Path: `device/vending/initiator`,
            Body: JSON.stringify({
                Initiator: initiator
            })
        }, { responseType: "text" }).toPromise();
    }

    getInitiatorQRCodeImage(initiator: string): Promise<ArrayBuffer> {
        return this.http.post(`${this.apiUrl}/api/qrcommand/image`, {
            Method: "Post",
            Path: `device/vending/initiator`,
            Body: JSON.stringify({
                Initiator: initiator
            })
        }, { responseType: "arraybuffer" }).toPromise();
    }
}

// This is a utility type for the device clone and import endpoints.
export type DeviceCloneQuery = {
    SourceID: string,
    TargetID: string,
    OverwriteExisting: boolean,
    TargetPostfix: string,
    TargetLocationID: string,
    CloneConfig: boolean,
    CloneProducts: boolean,
    CloneMenu: boolean,
    ClonePrices: boolean,
    CloneSelections: boolean,
    SkipIfExisting: boolean
};
