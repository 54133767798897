import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../../../../environments/runtime-environment";
import { Access } from "../../../../../../shared-gen/Model/Auth/Access";
import { Feature } from "../../../../../../shared-gen/Model/Auth/Feature";
import { ProductBase } from "../../../../../../shared-gen/Model/Products/ProductBase";
import { Currency } from "../../../../../../shared-gen/Model/Utils/Currency";
import { EntityType } from "../../../../../../shared-gen/Model/Utils/EntityType";
import { Localized } from "../../../../../../shared/localized";
import { TranslationService } from "../../../../../../shared/services/translation.service";
import { UserStore } from "../../../../../@core/stores/user.store";
import { getUrl } from "../../../../../@helper/core";
import { hasRights } from "../../../../../auth/iovent/rights";
import { DataService, ListOptions, ListResponse } from "../../../../../services/data.service";
import { GuiService } from "../../../../../services/gui.service";
import { StorageService } from "../../../../../services/storage.service";
import { ProductType } from "shared-gen/Model/Products/ProductType";
import debounce from 'lodash.debounce';
import { flatten } from 'lodash';
import { EversysProductGeneratorDialog } from "../eversys-product-generator/eversys-product-generator-dialog.component";

const PRODUCT_TYPE_GROUP_KEY: string = "product_type_groups";

interface ProductTypeGroup {
    name: string,
    productTypes: ProductType[]

}

@Component({
    selector: "ngx-product-table",
    templateUrl: "./product-table.component.html",
    styleUrls: ["./product-table.component.scss"]
})
export class ProductTableComponent extends Localized implements OnInit {

    env = environment();
    products_result: ListResponse;
    products: ProductBase[];
    currency = Currency.EUR;
    isEditable = false;
    hasRightEversysProductGenerator = false;

    productPageSize: number = 50;
    productSortBy: string = "ID";
    productOrderAsc: boolean = true;

    openProducts: any[] = [];

    //if set true the Reports will be listed infinite
    infiniteScroll: boolean = environment().reports.infiniteScroll;

    availableProductTypeGroups: ProductTypeGroup[] = [
        {
            name: 'Hotdrink',
            productTypes: [ProductType.Eversys, ProductType.WMF]
        },
        {
            name: 'Article',
            productTypes: [ProductType.Article, ProductType.SandenVendoVM, ProductType.SelfService]
        }
    ]

    filteredProductTypeGroups: ProductTypeGroup[] = [...this.availableProductTypeGroups];

    constructor(protected dataService: DataService,
        protected storageService: StorageService,
        private router: Router,
        private route: ActivatedRoute,
        private guiService: GuiService,
        translationService: TranslationService,
        private userStore: UserStore) {
        super(translationService);
        this.currency = userStore.getCurrency();
    }

    async ngOnInit() {
        console.log(this.route.snapshot.parent);

        this.isEditable = hasRights(Feature.Products, this.userStore.getUser(), Access.Write);
        this.hasRightEversysProductGenerator = hasRights(Feature.EversysProductGenerator, this.userStore.getUser(), Access.Write);

        let savedFilters = this.getSavedProductTypeGroupNamesOrDefault();
        this.filteredProductTypeGroups = this.retrieveObjectReference(this.availableProductTypeGroups, savedFilters);

        const pSort = this.storageService.getValue(this.getStorageKey("product_sort")),
            pAsc = this.storageService.getValue(this.getStorageKey("product_asc")),
            pOpened = this.storageService.getValue(this.getStorageKey("product_opened"));
        if (pSort)
            this.productSortBy = pSort;
        if (pAsc)
            this.productOrderAsc = pAsc;

        await this.load();

        if (pOpened) {
            this.openProductBaseByID(pOpened);
        }

    }

    /**
     * Nebular checks for equality of object references not value equality.
     * This function filters an array of select options. A value remains in the returned array
     * iff it's name is contained in the allowedNames array.
     */
    private retrieveObjectReference(optionArray: ProductTypeGroup[], allowedNames: string[]) {
        return optionArray.filter(it => allowedNames.includes(it.name));
    }

    /**
     * Retrieves the saved product type group names from the browsers persistent storage.
     * If this is null then an array with all available names will be returned.
     */
    private getSavedProductTypeGroupNamesOrDefault(): string[] {
        return this.storageService.getValue(this.getStorageKey(PRODUCT_TYPE_GROUP_KEY)) ||
            this.availableProductTypeGroups.map(productTypeGroup => productTypeGroup.name);
    }

    /**
     * Saves product type group names to the browsers persistent storage.
     */
    private setSavedProductTypeGroupNames(productTypeGroups: ProductTypeGroup[]): void {
        if (productTypeGroups == null) {
            return;
        }
        const filteredNames = this.filteredProductTypeGroups.map(productTypeGroup => productTypeGroup.name);
        this.storageService.setValue(this.getStorageKey(PRODUCT_TYPE_GROUP_KEY), filteredNames);
    }

    async load(page = 1) {
        this.products_result = await this.dataService.getPagedListOfDataType("products",
            <ListOptions>{
                OrderBy: this.productSortBy,
                OrderAsc: this.productOrderAsc,
                PageIndex: page - 1,
                PageSize: this.productPageSize,
            },
            {
                ProductType: flatten(this.filteredProductTypeGroups.map(productTypeGroup => productTypeGroup.productTypes))
            },
            "unfiltered"
        );
        this.products = this.products_result.Items;
    }

    triggerDebouncedLoad = debounce(() => {
        console.log('trigger');
        this.load();
    }, 1000);

    changeFilteredProductTypeGroups(nextFilteredProductTypeGroups: ProductTypeGroup[]) {
        console.log('changeFilteredProductTypeGroups ', nextFilteredProductTypeGroups);
        this.filteredProductTypeGroups = nextFilteredProductTypeGroups;
        this.setSavedProductTypeGroupNames(this.filteredProductTypeGroups);
        this.triggerDebouncedLoad();
    }

    openProductBase(event: Event, product: ProductBase) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (product.Instances.length > 1) {
            this.openProductBaseByID(product.ID);
        }
    }

    productIsOpen(product: ProductBase) {
        return this.productIsOpenByID(product.ID);
    }

    openProductBaseByID(productBaseID: string) {
        if (this.productIsOpenByID(productBaseID)) {
            this.storageService.deleteValue(this.getStorageKey("product_opened"));
            this.openProducts.splice(this.openProducts.indexOf(productBaseID), 1);
        } else {
            this.storageService.setValue(this.getStorageKey("product_opened"), productBaseID);
            this.openProducts.push(productBaseID);
        }
    }

    productIsOpenByID(productBaseID: string) {
        return this.openProducts.indexOf(productBaseID) !== -1;
    }

    singleInstance(product: ProductBase) {
        if (product.Instances && product.Instances.length == 1) {
            return product.Instances[0];
        }
        return undefined;
    }

    sortedByProductBase(field) {
        if (field == this.productSortBy) {
            return "sorted-" + (this.productOrderAsc ? "asc" : "desc");
        }
        return "";
    }

    async sortProductBase(field) {
        if (this.productSortBy == field) {
            this.productOrderAsc = !this.productOrderAsc;
        } else {
            this.productOrderAsc = true;
        }
        this.productSortBy = field;

        this.storageService.setValue(this.getStorageKey("product_sort"), field);
        this.storageService.setValue(this.getStorageKey("product_asc"), this.productOrderAsc);

        await this.load();
    }

    async productPageChanged(page) {
        await this.load(page);
    }

    async onProductBasePageSizeChange() {
        await this.load();
    }

    protected getStorageKey(type: string = "current_page") {
        const url = getUrl(this.route.snapshot);
        return url + "_" + type;
    }

    add() {
        this.router.navigate(['../add'], { relativeTo: this.route });
        //this.router.navigate(["/pages/configuration/products/add"]);
    }

    edit(product: ProductBase) {
        if (!this.isEditable) {
            return;
        }
        this.router.navigate(['../detail', product.ID], { relativeTo: this.route });
    }

    getEditProductLink(productID: string) {
        if (!this.isEditable) {
            return '#';
        }
        
        return `${this.router.url}/../detail/${productID}`;
    }

    async delete(product: ProductBase) {
        this.guiService.confirmDeleteDialog(async (result) => {
            if (result) {
                // do confirmation actions
                try {
                    await this.dataService.delete(product.ID, EntityType.ProductBase);
                    this.guiService.showToast(this.translate("🌐Products.Table.ProductDeleted"));
                    this.load();
                } catch (e) {
                    this.guiService.showErrorMessageFromError(e);
                }
            }
        }, this.translate("🌐Products.Table.AreYouSureYouWantToDeleteTheProduct"));
    }

    openEversysProductGenerator() {
        this.guiService.showDialog({}, EversysProductGeneratorDialog, () => {}, true);
    }
}
